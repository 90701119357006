const axios = require('axios');
const { configStore } = require("../store/config");
const { url } = require('./url')
const { getUserCity } = require('../api/count')

/**
 * 和风天气
 * 获取城市详细信息 （Location ID） 用于查询天气
 * @param {String} name 城市名称
 */
const utilGetLocation = async name => {
  const configData = configStore();
  const cityName = name ? name : (await getUserCity()).provinceName;
  return new Promise((resolve, reject) => {
    axios.get(`https://geoapi.qweather.com/v2/city/lookup?key=${configData.config.weaTherKey}&location=${cityName}`).then(res => {
      if (res.status === 200 && res.data.code == 200 && res.data.location.length) {
        let { id, name } = res.data.location[0];
        sessionStorage.cityId = id; // 城市Id
        resolve(id)
      }
    })
  })
}

/**
 * 和风天气
 * 获取当前城市的实时天气 和 空气质量
 * @param {String} cityId
 */
const utilGetWeaTher = async cityId => {
  const configData = configStore();
  let hflocationId = sessionStorage.cityId || await utilGetLocation()
  // 天气信息
  let weather = new Promise((resolve, reject) => {
    axios.get(`https://api.qweather.com/v7/weather/now?key=${configData.config.weaTherKey}&location=${hflocationId}`).then(res => {
    if(res.status === 200 && res.data.code == 200) {
        let { now } = res.data;
        resolve(now)
      }
    })
  })
  // 空气质量
  let air = new Promise((resolve, reject) => {
    axios.get(`https://api.qweather.com/v7/air/now?key=${configData.config.weaTherKey}&location=${hflocationId}`).then(res => {
      if(res.status === 200 && res.data.code == 200) {
        let { now } = res.data;
        resolve(now)
      }
    })
  })

  return Promise.all([weather, air])
}


/**
 * 获取当前详细时间
 * @returns
 */
const utilTimeShow = () => {
  var nowdate = new Date();
  var year = nowdate.getFullYear(), // 年
      month = checkTime(nowdate.getMonth() + 1), // 月
      date = checkTime(nowdate.getDate()), // 日
      day = nowdate.getDay(), // 星期
      week = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"],
      h = checkTime(nowdate.getHours()), // 时
      m = checkTime(nowdate.getMinutes()), // 分
      s = checkTime(nowdate.getSeconds()) // 秒

  return {
    date: `${year}-${month}-${date}`,
    time: `${h}:${m}`,
    weekName: week[day]
  }
}

/**
 * 格式化时间
 * @param {Number} i
 * @returns
 */
var checkTime = function (i) {
  if (i < 10) {
      i = "0" + i;
  }
  return i;
}

/**
 * 查询几天前的日期
 * @param {Number} day 天数
 * @returns 日期 2022-02-22
 */
const getDay = (day = 0) => {
	var today = new Date();
	var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
	today.setTime(targetday_milliseconds);
	var tYear = today.getFullYear();
	var tMonth = today.getMonth();
	var tDate = today.getDate();
	tMonth = checkTime(tMonth + 1);
	tDate = checkTime(tDate);
	return tYear + "-" + tMonth + "-" + tDate;
}

// startDate: 计划开始时间； endDate：计划结束时间；dayLength：每隔几天，0-代表每天，1-代表日期间隔一天
const getDateStr = (startDate, endDate, dayLength = 0) => {
	let timeArr = new Array()
	let getDate = startDate
	do {
		getDate = getTargetDate(getDate, dayLength);
		timeArr.push(getDate)
	} while (Date.parse(getDate) <= Date.parse(endDate))
	timeArr.pop()
	return timeArr
}

getTargetDate = (date,dayLength) => {
	dayLength = dayLength + 1;
    var tempDate = new Date(date);
    tempDate.setDate(tempDate.getDate() + dayLength);
    var year = tempDate.getFullYear();
    var month = tempDate.getMonth() + 1 < 10 ? "0" + (tempDate.getMonth() + 1) : tempDate.getMonth() + 1;
    var day = tempDate.getDate() < 10 ? "0" + tempDate.getDate() : tempDate.getDate();
    return year + "-" + month + "-" + day;
}

/**
 * 时间计算
 * timetamp 秒数
 */
 const timeToDate = (timer = null) => {
	let tip = ''; // 时间
	let unit = '天'; // 单位
  let enUnit
	switch (true) {
		case timer >= 0 && timer < 3600:
			tip = parseInt(timer / 60)
			unit = '分钟'
      enUnit = 'min'
			break;
		case timer >= 3600 && timer < 86400:
			tip = parseInt(timer / 3600)
			unit = '小时'
      enUnit = 'h'
			break;
		// case timer >= 86400 && timer < 259200:
		default:
			tip = parseInt(timer / 86400)
			unit = '天'
      enUnit = 'day'
	}
	return { tip, unit, enUnit }
}

/**
 * base64转换成文件
 * data:base64图片格式字符串
 * filename：文件名称
 */
const base64toFile = (data, fileName) => {
  const dataArr = data.split(",");
  const byteString = atob(dataArr[1]);
  const options = {
    type: "image/jpeg",
    endings: "native"
  };
  const u8Arr = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    u8Arr[i] = byteString.charCodeAt(i);
  }
  return new File([u8Arr], fileName + ".jpg", options);//返回文件流
}

/**
 * 格式化图片路径
 * @param {*} url
 * @returns
 */
const formattingImg = (urlPath, type = 'img') => {
  if (!urlPath) return ''
  let imgUrl = ''
  if(urlPath.indexOf('data:image/jpeg') !== -1) return urlPath
  else if(urlPath.indexOf('http') !== -1) imgUrl = urlPath
  else imgUrl = `${url}/service-core/api/download/${urlPath}`
  return imgUrl
}


module.exports = {
  utilGetLocation,
  utilGetWeaTher,
  utilTimeShow,
  getDay,
  getDateStr,
  timeToDate,
  base64toFile,
  formattingImg
}

